.logo {
	height: 4rem;

	&:hover {
		transform: scale(1.2);
	}
}

.logoMain {
	&:hover {
		transform: scale(1.05);
	}
}

.logo, .logoMain {
	cursor: pointer;
	transition: transform 0.2s ease-out;
}

.logoDark {
	filter: grayscale(1) brightness(5);
}

.translucent {
	background-color: rgba(255,255,255,0.8) !important;
	backdrop-filter: blur(0.3rem);
}

.translucentDark {
	background-color: rgba(255,255,255,0.1) !important;
}
