.link {
	border-bottom: 0.1rem solid rgba(0,0,0,0);
	text-decoration: none !important;
	transition: border-color 0.2s ease-out;	

	&:hover {
		border-color: inherit;
		text-decoration: none !important;
	}
}

.disabled {
	cursor: default !important;
	text-decoration: none !important;
}
