.logoBrand {
	color: #471c13 !important;
}

.logoTracker {
	color: #f73007 !important;
}

.linkAlternative {
	border-bottom: 0 !important;
	transition: opacity 0.2s ease-out;	
	
	&:hover:not(:global(.disabled)) {
		opacity: 0.6;
	}
}
