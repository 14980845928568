.UiBarSearch {

	&::before {
		border-color: rgba(0,0,0,0.25) !important;
	}

	&::before, &::after {
		bottom: -0.3rem !important;
	}
	
}
