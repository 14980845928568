@font-face {
	font-family: "Work Sans";
	font-weight: normal;
	src: url("../public/assets/fonts/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Work Sans";
	font-weight: bold;
	src: url("../public/assets/fonts/WorkSans-SemiBold.ttf") format("truetype");
}

@media (orientation: landscape) {
	html {
		-webkit-text-size-adjust: 100%;
	}
}

* {
	border: 0;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 100%;
	margin: 0;
	max-width: 100%;
	outline: 0;
	padding: 0;
	vertical-align: baseline;

	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	&::selection {
		background: var(--theme-color);
		color: var(--base-color);
	}
}

html {
	font-family: "Work Sans", sans-serif, serif;
	font-size: 62.5%;
	font-weight: normal;
	min-width: 100%;
	overflow-y: scroll;
	scroll-padding-top: 9rem;
}

body, html, #react {
	min-height: 100vh;
}

.mui-autocomplete-nowrap {
	.MuiAutocomplete-tag {
		max-width: 85%;
	}
	.MuiInputBase-root {
		flex-wrap: nowrap;
	}
}

.mui-icon-button-focus-underline {
	&::after {
		background: var(--theme-color);
		bottom: -0.5rem;
		content: "";
		height: 0.3rem;
		opacity: 0;
		position: absolute;
		transition: opacity 0.1s ease-out;
		width: 80%;
	}
	&:hover {
		background-color: transparent !important;
	}
	&:hover, &:focus {
		&::after {
			opacity: 1;
		}
	}
}

.mui-input-border-theme-color {
	.MuiInputBase-root::before {
		border-color: var(--theme-color) !important;
	}
}

.mui-input-border-theme-color .MuiInput-underline.Mui-disabled:before {
	border-bottom-color: rgba(0,0,0,0.42) !important;
}

.mui-table {

	td, th {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&.mui-table-no-shadow {
		.MuiPaper-root {
			box-shadow: none !important;
		}
	}

	&.mui-table-no-paging {

		// set min height for table container to stop 
		// nasty jump during load (when body is empty)
		[class^="Component-horizontalScrollContainer"] > div {
			min-height: 6.5rem;
		}

		table {

			// adjust bottom margin to remove pagination bar space
			margin-bottom: 0;

			// remove the last row border; there is no pagination bar
			.MuiTableBody-root {
				.MuiTableRow-root,
				.MuiTableRow-root:last-of-type .MuiTableCell-root {
					border-bottom: none !important;
				}
			}

		}

	}

	// selection checkbox widths
	.MuiTableCell-paddingNone {
		width: 4rem !important;
	}

	.MuiInputAdornment-positionEnd {
		display: none;
	}

	.MuiTableCell-root.MuiTableCell-head.MuiTableCell-paddingNone:first-child {
		width: 42px;
	}

	.MuiToolbar-root {
		background: var(--paper-color);
		color: var(--theme-color) !important;
		padding-left: 1.5rem;
		padding-right: 0;

		.MuiFormControl-root {
			padding-left: 0;
		}

		@media all and (max-width: 960px) {
			display: grid;
			grid-row-gap: 1rem;
			padding-top: 1.5rem;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0.75rem;
			text-align: center;

			&.MuiTablePagination-toolbar {
				padding-top: 0.75rem;
			}

			&.MuiToolbar-regular:not(.MuiTablePagination-toolbar) {
				padding-left: 1.5rem;
				padding-right: 1.5rem;
			}

			[class^="MTableToolbar-title"] .MuiTypography-root {
				white-space: normal !important;
			}

			[class^=MTableToolbar-spacer] {
				display: none;
			}

			.MuiTablePagination-selectRoot .MuiSelect-root > div {
				text-align-last: center;
			}

			.MuiTextField-root {
				order: 9000;
			}
		}
	}

	.MuiPaper-root > .MuiToolbar-root.MuiToolbar-regular:not(.MuiTablePagination-toolbar) {
		@media all and (max-width: 960px) {
			margin-bottom: 1.5rem;
		}
	}

}

.mui-table.has-icon {
	.MuiTableRow-root[mode="delete"] {
		td[colspan] {
			padding-left: 7.75rem;
		}
	}
	&.extra-delete-padding .MuiTableRow-root[mode="delete"] {
		td[colspan] {
			padding-left: 9rem;
		}
	}
	&.has-detail-panel .MuiTableRow-root[mode="delete"] {
		td[colspan] {
			padding-left: 10.5rem;
		}
	}
}

.mui-table-selectable .MuiTableRow-root:hover:not(.MuiTableRow-head):not(.MuiTableRow-footer) {
	filter: contrast(0.8);
}

.mui-table-toolbar, .mui-table-toolbar-sticky .MuiToolbar-root {
	@media all and (min-height: 920px) {
		position: sticky;
		top: 5.6rem;
		z-index: 1000;
	}
}

.MuiAccordion-root:before {
	opacity: 0 !important;
}

.MuiAlert-icon {
	flex-shrink: 0 !important;
}

.MuiAppBar-root {
	box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.1) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
	color: var(--theme-color) !important;
}

.MuiDialog-root {
	z-index: 1000000 !important;
}

.MuiInput-underline.Mui-disabled:before {
	border-bottom-style: solid !important;
}

.MuiLink-root:hover, .MuiLink-root:focus, .MuiLink-root:focus-within {
	cursor: pointer;
	text-decoration: underline;
}

.MuiPopover-root, .MuiAutocomplete-popper {
	z-index: 9999999 !important;
}

.MuiOutlinedInput-root {
	border-radius: 0 !important;
}

.MuiSnackbar-root {
	@media all and (max-width: 960px) {
		margin-bottom: 4.5rem;
	}
}

.MuiSnackbarContent-message {
	white-space: pre-line;
}

.MuiTablePagination-root.MuiTableCell-root {
	border-bottom: none !important;
}

.MuiTableRow-root[mode="update"] {
	border-bottom: none !important;
}

.MuiTab-root {
	min-width: 12rem !important;

	&.Mui-selected {
		&::after {
			content: "";
			background: var(--theme-color);
			bottom: 0;
			display: block;
			height: 0.2rem;
			position: absolute;
			width: 100%;
		}
	}
}

.MuiTabs-root {
	width: 100% !important;
}

.MuiTabs-flexContainer {
	width: fit-content;
}

.MuiTabs-indicator {
	display: none;
}

.MuiTabs-scroller {
	display: flex !important;
	justify-content: center;
}

.MuiTablePagination-root {
	overflow-x: hidden !important;
}

.MuiTableCell-paddingNone:last-child:not(.MuiTableCell-footer),
.MuiTableCell-paddingCheckbox:last-child:not(.MuiTableCell-footer) {
	width: 18rem !important;

	div:first-child:not(.MuiBox-root):not(.MuiToolbar-root) {
		justify-content: flex-end;
	}
}

.MuiTablePagination-root .MuiTypography-root.MuiTypography-caption {
	margin: 0 1rem;
}

.MuiTooltip-popper {
	z-index: 10000000 !important;
}

.MuiTooltip-tooltip {
	font-size: 1.2rem !important;
	text-align: center;
}

::-webkit-calendar-picker-indicator {
	display: none;
}
